// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/cart/ShoppingCartContents.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/cart/ShoppingCartContents.tsx");
  import.meta.hot.lastModified = "1734124776000";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import ProgressBar from '~/components/products/ProgressBar';
import React, { useEffect, useState } from 'react';
import { AmountAdjuster } from '~/components/cart/AmountAdjuster';
import { addItemToOrder } from '~/providers/external/orders/order';
import { getPoolAmount } from '~/providers/external/pooling/pool';
export function ShoppingCartContents({
  orderLines,
  // From Vendure
  orders,
  // From Biosonah
  refreshOrders,
  currencyCode,
  editable = true,
  adjustOrderLine,
  removeItem
}) {
  _s();
  const isEditable = editable !== false;
  const [poolAmounts, setPoolAmounts] = useState({});
  const amount = async (type, productId) => {
    try {
      const response = await getPoolAmount(productId);
      if (response && response.code === 200) {
        return response.items[type];
      } else return 0;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };
  const amountInPool = async productId => {
    return amount('amountInPool', productId);
  };
  const [filledAmounts, setFilledAmounts] = useState({});
  const filledAmount = async productId => {
    return amount('filledPools', productId);
  };
  useEffect(() => {
    const fetchAmounts = async () => {
      if (orders) {
        const fAmounts = {};
        const pAmounts = {};
        for (const order of orders) {
          const line = orderLines.find(l => l.id === String(order.vendureOrderLineId));
          if (line) {
            pAmounts[line.productVariant.product.id] = await amountInPool(line.productVariant.product.id);
            fAmounts[line.productVariant.product.id] = await filledAmount(line.productVariant.product.id);
          }
        }
        setPoolAmounts(pAmounts);
        setFilledAmounts(fAmounts);
      }
    };
    fetchAmounts();
  }, [orders]);
  if (!orders) return <></>;
  return <div className="flow-root">
      <ul role="list" className="-my-6 divide-y divide-gray-200">
        {(orders ?? []).map(order => {
        const line = orderLines.find(l => l.id === String(order.vendureOrderLineId));
        if (!line) return <li key={order.id} className="py-6 flex"></li>;
        const poolAmount = poolAmounts[line.productVariant.product.id] || 0;
        const filledAmount = filledAmounts[line.productVariant.product.id] || 0;
        return <li key={order.id} className="py-6 flex">
              <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                <img src={line.featuredAsset?.preview + '?preset=thumb'} alt={line.productVariant.name} className="w-full h-full object-center object-cover" />
              </div>

              <div className="ml-4 flex-1 flex flex-col">
                <div>
                  <div className="flex justify-between text-base font-bold text-gray-900 ">
                    <h3>
                      <Link to={`/products/${line.productVariant.product.slug}`}>
                        {line.productVariant.name}
                      </Link>
                    </h3>
                    <p className="ml-4">
                      {currencyCode}{' '}
                      {Number(order.unitCosts).toLocaleString('de-CH', {
                    maximumFractionDigits: 2
                  })}{' '}
                      <span className="product-catalog-product-price-type">
                        {' '}
                        /{order.unit}
                      </span>
                    </p>
                  </div>
                </div>

                <p className="flex-1 flex items-center text-sm">
                  Bio Knospe Schweiz
                </p>

                <label htmlFor={`quantity-${line.id}`} className="mr-2 cartContent-text">
                  Menge in {order.unit || 'N/A'}
                </label>

                <div className="flex-1 flex items-center text-sm mb-2">
                  {editable ? <AmountAdjuster qtyInCart={line.quantity} baseUnitOfMeasure={order.unit || 'N/A'} increaseBy={line.productVariant.customFields?.IncreaseBy || 1} minOrderValue={line.productVariant.customFields?.Min_Order_Value || 1} inCart={true} addToCart={() => {}} adjustInCart={async amount => {
                const actualAmount = order.unit === 'KG' ? amount / 1000 : amount;
                adjustOrderLine && adjustOrderLine(line.id, amount);
                await addItemToOrder(order.customerId, order.id, line.id, String(order.productVariantId), actualAmount, order.unit);
                refreshOrders();
              }} deleteFromCart={async () => {
                removeItem && removeItem(line.id);
                await addItemToOrder(order.customerId, order.id, line.id, String(order.productVariantId), 0, order.unit);
                refreshOrders();
              }} cssPostFix="-inCart" showUnit={false} /> : <div className="text-gray-800">
                      <span className="mr-1">Bestellmenge:</span>
                      <span className="font-medium">
                        {order.quantity} {order.unit}
                      </span>
                    </div>}

                  <div className="flex-1"></div>
                  <div className="flex">
                    {isEditable && <button type="submit" name="removeItem" value={line.id} className="font-medium text-primary-600 hover:text-primary-500" onClick={async () => {
                  removeItem && removeItem(line.id);
                  await addItemToOrder(order.customerId, order.id, line.id, String(order.productVariantId), 0, order.unit);
                  refreshOrders();
                }}>
                        Löschen
                      </button>}
                  </div>
                </div>
                <div id="product-catalog-progressbar-button-container w-full">
                  <ProgressBar amountInPool={poolAmount} filledPools={filledAmount} color={'custom-progress-bar'} maxValue={line.productVariant.customFields?.Min_Order_Value || 100} currentValue={order.quantity} infoTextClassname={'product-catalog-progressbar-text w-full'} baseUnit={order.unit} takeRest={false} />
                </div>
              </div>
            </li>;
      })}
      </ul>
    </div>;
}
_s(ShoppingCartContents, "hSCqNzFC/l5X6f/4YWxv0TXH/1Y=");
_c = ShoppingCartContents;
var _c;
$RefreshReg$(_c, "ShoppingCartContents");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;